var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main stock-detail" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                attrs: {
                  title: "委托客户",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.stockForm.enterpriseName
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "货权人",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.stockForm.cargoRightsName
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "仓库名称",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.stockForm.warehouseName
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "货物名称",
                  value: _vm.utils.isEffectiveCommon(_vm.stockForm.cargoName)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "当前库存柜数",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.stockForm.containerListLength
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "总重量（KG）",
                  value: _vm.utils.moneyFormat(_vm.stockForm.weight)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "总件数",
                  value: _vm.utils.isEffectiveCommon(_vm.stockForm.pieceNumber)
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "van-common-detail" }, [
        _c(
          "div",
          { staticClass: "table" },
          [
            _vm._m(2),
            _vm._l(_vm.stockForm.containerList, function(item, index) {
              return _c(
                "div",
                { key: item.id, staticClass: "tr" },
                [
                  _c("div", { staticClass: "fl" }, [
                    _vm._v(" " + _vm._s(index + 1) + " ")
                  ]),
                  _c("div", { staticClass: "fl" }, [
                    _vm._v(" " + _vm._s(item.cabinetNo) + " ")
                  ]),
                  _c("div", { staticClass: "fl" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.actualInDate.split(" ")[0] ||
                            _vm.utils.dateFormat(item.actualInDate)
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "fl list-detail",
                      on: {
                        click: function($event) {
                          return _vm.detail(item)
                        }
                      }
                    },
                    [_vm._v(" 查看详情 ")]
                  ),
                  _c("div", { staticClass: "clearfix" }),
                  _c("van-divider")
                ],
                1
              )
            })
          ],
          2
        )
      ]),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 基本信息 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 货物信息 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "th" }, [
      _c("div", { staticClass: "fl" }, [_vm._v(" 序号 ")]),
      _c("div", { staticClass: "fl" }, [_vm._v(" 柜号 ")]),
      _c("div", { staticClass: "fl" }, [_vm._v(" 实际入库时间 ")]),
      _c("div", { staticClass: "fl" }, [_vm._v(" 查看 ")]),
      _c("div", { staticClass: "clearfix" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }