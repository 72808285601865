<template>
  <div class="van-main stock-detail">
    <div class="area-title">
      <p class="title">
        基本信息
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell title="委托客户" :value="utils.isEffectiveCommon(stockForm.enterpriseName)" />
        <van-cell title="货权人" :value="utils.isEffectiveCommon(stockForm.cargoRightsName)" />
        <van-cell title="仓库名称" :value="utils.isEffectiveCommon(stockForm.warehouseName)" />
        <van-cell title="货物名称" :value="utils.isEffectiveCommon(stockForm.cargoName)" />
        <van-cell title="当前库存柜数" :value="utils.isEffectiveCommon(stockForm.containerListLength)" />
        <van-cell title="总重量（KG）" :value="utils.moneyFormat(stockForm.weight)" />
        <van-cell title="总件数" :value="utils.isEffectiveCommon(stockForm.pieceNumber)" />
      </van-cell-group>
    </div>
    <div class="area-title">
      <p class="title">
        货物信息
      </p>
    </div>
    <div class="van-common-detail">
      <div class="table">
        <div class="th">
          <div class="fl">
            序号
          </div>
          <div class="fl">
            柜号
          </div>
          <div class="fl">
            实际入库时间
          </div>
          <div class="fl">
            查看
          </div>
          <div class="clearfix" />
        </div>
        <div v-for="(item, index) in stockForm.containerList" :key="item.id" class="tr">
          <div class="fl">
            {{ index + 1 }}
          </div>
          <div class="fl">
            {{ item.cabinetNo }}
          </div>
          <div class="fl">
            {{ item.actualInDate.split(' ')[0] || utils.dateFormat(item.actualInDate) }}
          </div>
          <div class="fl list-detail" @click="detail(item)">
            查看详情
          </div>
          <div class="clearfix" />
          <van-divider />
        </div>
      </div>
    </div>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, uploader } from 'vant'
import BackButton from '@/components/back-button'
export default {
  components: {
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [uploader.name]: uploader
  },
  data () {
    return {
      id: this.$route.query.id,
      stockForm: {}
    }
  },
  computed: {
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.api.stock.detail(this.$route.query.id).then(result => {
        this.stockForm = result.data.value
        this.stockForm.containerListLength = result.data.value.containerList.length
      }).finally(() => {
      })
    },
    detail (row) {
      this.$router.push({ name: 'inventoryDetail', query: { id: this.id, rowId: row.id } })
    }
  }
}
</script>

<style lang="less" scoped>
.stock-detail {
  margin-bottom: 60px;
  .table {
    font-size: 14px;
    margin: auto 10px;
    padding-bottom: 60px;
    .th {
      background-color: rgb(242, 242, 242);
      border: 1px solid rgb(215, 215, 215);
      border-radius: 5px 5px 0px 0px;
      height: 46px;
      text-align: center;
      line-height: 46px;
      font-weight: 700;
      >div:first-child {
        width: 10%;
      }
      >div:nth-child(2) {
        width: 35%;
      }
      >div:nth-child(3) {
        width: 35%;
      }
      >div:nth-child(4) {
        width: 20%;
      }
    }
    .tr {
      height: 46px;
      text-align: center;
      line-height: 46px;
      font-weight: 400;
      >div:first-child {
        width: 10%;
      }
      >div:nth-child(2) {
        width: 35%;
      }
      >div:nth-child(3) {
        width: 35%;
      }
      >div:nth-child(4) {
        width: 20%;
      }
      .list-detail {
        color: #1989fa;
      }
    }
    .van-divider {
      margin-top: 0px;
    }
  }
}
</style>
